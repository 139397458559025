<template>
    <div>
        <div class="row my-3">
            <h1 class="col-sm-12">Catálogos</h1>
        </div>

        <DataGrid :configuracion="datagrid_config" :data="catalogos" @ready="buscar_btns">
            <button class="btn btn-secondary mr-2" @click="obtener_catalogos"><i class="fa-solid fa-rotate"></i> Actualizar</button>
            <button class="btn btn-primary" @click="show_modal = true"><i class="fa-solid fa-plus"></i> Crear</button>
        </DataGrid>

        <Form v-if="show_modal" :catalogo="catalogo" @close="cerrar_modal" @update="cerrar_modal(true)" />

    </div>
</template>

<script>

import DataGrid from '@/components/DataGridV3';
import Form from './Catalogo/Form.vue';
import apiCondusef from '@/apps/condusef/api/catalogos';



export default {
    components: { DataGrid, Form },
    data() {
        return {
            datagrid_config: {
                cols: {
                    nombre: 'Nombre',
                    codigo: 'Código',
                    descripcion: 'Descripción',
                    estatus: 'Estatus',
                    opciones: 'Opciones'
                },
                mutators: {
                    estatus(val, row, vue) {
                        return val == 1 ? 'Activo' : 'Inactivo';
                    },
                    opciones(val, row, vue) {
                        return '<button class="btn btn-sm btn-primary mr-2 btn-edit" data-catalogo="' + vue.$helper.toJson(row) + '" data-accion="editar"><i class="fa-regular fa-pen-to-square"></i></button>';
                    }
                },
                search: ['nombre', 'codigo', 'estatus'],
                selector: false
            },
            catalogos: [],
            catalogo: null,
            show_modal: false,
        }
    },
    mounted() {
        this.obtener_catalogos();
    },
    methods: {
        async obtener_catalogos() {
            try {
                this.catalogos = (await apiCondusef.listar_catalogos()).data;
            } catch (e) {
                this.$log.error(e);
                this.$helper.showAxiosError(e, 'Error');
            }
        },
        buscar_btns() {
            document.querySelectorAll('button.btn-edit').forEach(btn => {
                btn.removeEventListener('click', this.manejarAccion);
                btn.addEventListener('click', this.manejarAccion);
            });
        },
        manejarAccion(e) {

            let btn = e.target;

            if (btn.tagName == 'I')
                btn = btn.parentElement;

            let catalogo = this.$helper.fromJson(btn.dataset.catalogo);

            this.catalogo = catalogo
            this.show_modal = true;
        },
        cerrar_modal(recargar) {
            if (typeof recargar == 'undefined')
                recargar = false;

            this.show_modal = false;
            this.catalogo = null;

            if (recargar)
                this.obtener_catalogos();
        }
    }
}
</script>